<template lang="pug">
.book
  .page
    .subpage
      .row
        .col-sm-3
          .text-center
            img.logo(src='/static/assets/images/logo-brfin.png')
        .col-sm-9
          h3 BRFin
          p Av. Presidente Castelo Branco, 3557
          p Umuarama-PR - ao lado do corpo de bombeiros
          p Tel.: (44) 3055-2100 / whats: (44) 98403-2528
        router-view
</template>

<script>
export default {

}
</script>

<style lang="stylus" scoped>
.logo
  width 175px !important
body {
	margin: 0;
	padding: 0;
	background-color: #FAFAFA;
}

* {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

.page {
	width: 980px;
	min-height: 29.7cm;
	padding: 1cm;
	margin: 0cm auto;
	border: 1px #D3D3D3 solid;
	border-radius: 5px;
	background: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	.row {
		margin-right: 15px;
		margin-left: 15px;
	}
}

@page {
	margin: 0;
}

@media print {
	.page {
		width: 100%;
		margin: 0;
		border: initial;
		border-radius: initial;
		width: initial;
		min-height: initial;
		box-shadow: initial;
		background: initial;
		page-break-after: always;
		.row {
			margin-right: 0;
			margin-left: 0;
		}
	}
}

.text-center {
	text-align: center;
}

.text-center img {
	width: 72px;
}

p {
	margin: 0 0 5px !important;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
</style>
